import React, { useRef, ReactNode, memo } from 'react';
import {
    m,
    useScroll,
    useSpring,
    useReducedMotion,
    MotionValue,
    useTransform,
} from 'framer-motion';
import { ScrollOffset } from '@motionone/dom/types/gestures/scroll/types';

type ParallaxProps = {
    children: ReactNode;
    /**
     * Where the parallaxed element should react compared to its scroll parent. https://www.framer.com/docs/use-scroll/
     */
    offset?: ScrollOffset;
    /**
     * Array of values between 0 and 1.
     * The input range must always be a linear series of numbers, either counting up or counting down.
     * The values which are used to map to outputRange. They must be of same length!
     */
    inputRange?: Array<number>;
    /**
     * The range of the parallax. Eg. [-25, 25] would parallax 25px in either direction
     * (Where the point of parallax is determined by the offset property)
     * The output range must all be of the same type, but can be any type supported by Framer Motion, for instance numbers, colors, shadows etc.
     */
    outputRange?: Array<string | number>;
    /**
     * Choose 'spring' if there should be a smoothing effect. Choose 'instant' for instant parallax
     */
    type?: 'spring' | 'instant';
    /**
     * If parallax should be disabled
     */
    disabled?: boolean;
};

export const Parallax = memo(
    ({
        children,
        offset = ['start end', 'end start'],
        inputRange = [0, 1],
        outputRange = [-50, 50],
        type = 'spring',
        disabled = false,
        ...restProps
    }: ParallaxProps): JSX.Element => {
        const prefersReducedMotion = useReducedMotion();
        const parallaxRef = useRef<HTMLDivElement>(null);

        const { scrollYProgress } = useScroll({
            target: parallaxRef,
            offset,
        });

        const yRange = useTransform(scrollYProgress, inputRange, outputRange);

        const spring: MotionValue<number> = useSpring(yRange, {
            stiffness: 800,
            damping: 50,
        });

        const y = {
            spring: spring,
            instant: yRange,
        }[type];

        // Don't parallax if the user has "reduced motion" enabled
        if (prefersReducedMotion || disabled) {
            return <>{children}</>;
        }

        return (
            <m.div ref={parallaxRef} style={{ y }} {...restProps}>
                {children}
            </m.div>
        );
    }
);
