import { breakpoints, breaks, GeneratedThemeShade } from '$theme';
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { readableColorIsBlack as rIsBlack } from 'color2k';
import { ifProp, switchProp } from 'styled-tools';
import { Link, Parallax } from '~/shared/components';
import { cover } from '~/shared/utils/styled';
import { gradient } from '~/shared/utils/styled/gradient';
import { getClampFullRange } from '~/theme/utils/getClampValue';
import { AlignContent } from '../AlignContent';
import { M10HeroProps } from './M10Hero';

type StyledM10Props = Pick<M10HeroProps, 'height' | 'mediaLayout' | 'backgroundColor'> &
    Pick<GeneratedThemeShade, 'backgroundShade'> & {
        index: number;
    };
type StyledM10ContentProps = {
    height: M10HeroProps['height'];
    mediaLayout: M10HeroProps['mediaLayout'];
    mediaAlignment: M10HeroProps['mediaHorizontalAlignment'];
    contentAlignment: M10HeroProps['horizontalAlignment'];
};

type StyledM10MediaWrapperProps = Pick<M10HeroProps, 'horizontalAlignment' | 'backgroundColor'> & {
    hasGradient: boolean;
};

export const StyledM10TextContentWrapper = styled.div({
    zIndex: 1,
    pointerEvents: 'none',
});

export const StyledM10AlignContent = styled(AlignContent)(({ theme }) => ({
    height: '100%',
    width: '100%',
    padding: `${theme.spaces['4']} ${theme.gridConfig.gutter} ${theme.spaces['6']}`,
    [breakpoints.sm]: {
        padding: `${theme.spaces['7']} 0`,
    },
}));

export const StyledMediaWrapper = styled.div<StyledM10MediaWrapperProps>(
    ({
        hasGradient,
        theme: {
            animations,
            colors: { dark, light },
        },
        backgroundColor,
        horizontalAlignment,
    }) => {
        const gradientColor = rIsBlack(backgroundColor || light) ? light : dark;

        return {
            position: 'relative',
            height: '100%',
            width: '100%',

            ':after': {
                content: hasGradient ? '""' : 'none',
                ...cover,
                pointerEvents: 'none',
                animationDuration: animations.getDuration('slow01'),
                animationTimingFunction: animations.getEasing('entrance', 'expressive'),
                animationFillMode: 'both',
                animationName: keyframes({
                    from: { opacity: 0 },
                    to: { opacity: 1 },
                }),
                ...gradient(gradientColor, 'bottom'),
                [breakpoints.sm]: {
                    ...gradient(gradientColor, horizontalAlignment),
                },
            },
        };
    }
);

export const StyledParallaxWrapper = styled(Parallax)({
    height: '100%',
    width: '100%',
});

export const StyledM10 = styled.div<StyledM10Props>(
    ({ theme, backgroundColor, backgroundShade, index }) => ({
        position: 'relative',
        width: '100%',
        margin: '0 auto',
        borderStyle: 'solid',
        borderTopWidth: index === 0 ? 0 : theme.traits.line.width,
        borderBottomWidth: theme.traits.line.width,
        borderColor:
            backgroundShade === 'light'
                ? theme.traits.line.color
                : theme.traits.line.colorAlternate,
        backgroundColor,
        overflow: 'hidden', // Parallax
    }),

    switchProp('height', {
        compact: {
            minHeight: getClampFullRange(150, 225),
            [breakpoints.sm]: {
                minHeight: `min(40vh, ${getClampFullRange(225, 300)})`,
            },
        },
        narrow: {
            minHeight: getClampFullRange(350, 525),
            [breakpoints.sm]: {
                minHeight: `min(50vh, ${getClampFullRange(350, 525)})`,
            },
        },
        normal: {
            minHeight: getClampFullRange(450, 675),
            [breakpoints.sm]: {
                minHeight: `min(70vh, ${getClampFullRange(450, 675)})`,
            },
        },
        tall: ({ theme: { sizes } }) => ({
            minHeight: getClampFullRange(600, 900),
            [breakpoints.sm]: {
                minHeight: `min(calc(100vh - ${
                    sizes.N20headerHeightDesktop + sizes.metaHeightDesktop
                }px), ${getClampFullRange(600, 900)})`,
            },
        }),
    }),

    switchProp('mediaLayout', {
        split: {
            // Overwrite minHeight on split heroes, so they are entirely content based on mobile
            [`@media (max-width: ${breaks.sm - 1}px)`]: {
                minHeight: 0,
            },
        },
        full: ({ theme }) => ({
            ...(theme.traits.line.stylisticVisible ? {} : { border: 'none' }),
        }),
    })
);

export const StyledM10Content = styled.div<StyledM10ContentProps>(
    {
        height: 'auto',
        minHeight: 'inherit',
        maxHeight: 1200,
        width: '100%',
        margin: '0 auto',
        display: 'grid',
        [breakpoints.xs]: {
            maxWidth: '100%',
        },
    },
    ifProp({ mediaLayout: 'split' }, ({ theme, mediaAlignment }) => {
        if (!mediaAlignment) {
            return;
        }
        const capitalizedMediaAlignment =
            mediaAlignment === 'left' ? 'Left' : mediaAlignment === 'right' ? 'Right' : '';
        return {
            [`${StyledM10AlignContent}`]: {
                [breakpoints.sm]: {
                    [`padding${capitalizedMediaAlignment}`]: `calc(4.15vw - ${theme.gridConfig.gap})`,
                },
            },
        };
    }),

    switchProp('contentAlignment', {
        left: {
            justifyContent: 'flex-start',
        },
        center: {
            justifyContent: 'center',
        },
        right: {
            justifyContent: 'flex-end',
        },
    }),

    switchProp('mediaLayout', {
        full: ({ theme }) => ({
            [`${StyledMediaWrapper}`]: {
                top: 0,
                left: 0,
                position: 'absolute',
            },
            [`${StyledM10AlignContent}`]: {
                width: '100%',
                margin: '0 auto',
                paddingLeft: theme.gridConfig.gutter,
                paddingRight: theme.gridConfig.gutter,
            },
        }),

        split: ({ theme, mediaAlignment, height }) => ({
            display: 'flex',
            flexDirection: 'column-reverse',
            [`${StyledMediaWrapper}`]: {
                position: 'relative',
                width: `100%`,
                aspectRatio: height === 'compact' ? '5/2' : '5/4',
                overflow: 'hidden',
            },
            [breakpoints.sm]: {
                display: 'grid',
                gap: theme.gridConfig.gap,
                padding: `0 ${theme.gridConfig.gutter}`,
                gridTemplateColumns: `repeat(${theme.gridConfig.columnCount.md}, 1fr)`,
                gridTemplateAreas:
                    mediaAlignment === 'left'
                        ? `"${'media '.repeat(8)}${'content '.repeat(4)}"`
                        : `"${'content '.repeat(4)}${'media '.repeat(8)}"`,
                [`${StyledMediaWrapper}`]: {
                    gridArea: 'media',
                    width: `calc(100% + ${theme.gridConfig.gutter})`,
                    transform: `translateX(${
                        mediaAlignment === 'left' ? `calc(${theme.gridConfig.gutter} * -1)` : '0'
                    })`,
                    aspectRatio: 'unset',
                },
                [`${StyledM10TextContentWrapper}`]: {
                    gridArea: 'content',
                },
            },
        }),
    })
);

export const StyledM10HeroImageWrapper = styled.div<{ srcFormat: 'landscape' | 'portrait' }>(
    ({ srcFormat }) => ({
        height: '100%',
        display: srcFormat === 'landscape' ? 'none' : 'initial',
        [breakpoints.sm]: {
            display: srcFormat === 'portrait' ? 'none' : 'initial',
        },
    })
);

export const StyledM10CoverLink = styled(Link)({
    ...cover,
});
