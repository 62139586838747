import React from 'react';
import { useCookieConsent } from '$shared/hooks';
import { ImageMedia, Link } from '~/lib/data-contract';
import { LegacyImage } from '$shared/components';
import {
    StyledM10CoverLink,
    StyledM10HeroImageWrapper,
} from '$templates/blocks/components/M10Hero/styled';
import { imageRatio } from '$templates/blocks/components/M10Hero/M10HeroSettings';

type M10ImagePropsType = {
    mediaType: 'image' | 'video';
    isFullWidth: boolean;
    index: number;
    mediaLayout: 'full' | 'split';
    height: 'compact' | 'narrow' | 'normal' | 'tall';
    skeletonShade: 'none' | 'dark' | 'light';
    image?: ImageMedia;
    callToAction?: Link;
    sizes?: JSX.IntrinsicElements['img']['sizes'];
};

export const M10HeroImage = ({
    mediaType,
    image,
    isFullWidth,
    index,
    mediaLayout,
    height,
    skeletonShade,
    callToAction,
    sizes,
}: M10ImagePropsType) => {
    const { marketing, statistic } = useCookieConsent();
    const shouldLinkCover = Boolean(isFullWidth && callToAction?.url);

    return (
        <>
            {(mediaType === 'image' || (mediaType === 'video' && !(marketing && statistic))) &&
                image?.src && (
                    <>
                        <StyledM10HeroImageWrapper srcFormat="portrait">
                            <LegacyImage
                                {...image}
                                src={image.src}
                                layout="fill"
                                objectFit="cover"
                                sizes={sizes}
                                showSpinner={!isFullWidth}
                                skeletonShade={skeletonShade}
                                priority={index < 2}
                                cW={imageRatio['mobile'][mediaLayout][height][0]}
                                cH={imageRatio['mobile'][mediaLayout][height][1]}
                            />
                        </StyledM10HeroImageWrapper>
                        <StyledM10HeroImageWrapper srcFormat="landscape">
                            <LegacyImage
                                {...image}
                                src={image.src}
                                layout="fill"
                                objectFit="cover"
                                sizes={sizes}
                                showSpinner={!isFullWidth}
                                skeletonShade={skeletonShade}
                                priority={index < 2}
                                cW={imageRatio['desktop'][mediaLayout][height][0]}
                                cH={imageRatio['desktop'][mediaLayout][height][1]}
                            />
                        </StyledM10HeroImageWrapper>
                        {shouldLinkCover && (
                            <StyledM10CoverLink
                                href={callToAction?.url || ''}
                                type="router"
                                aria-hidden="true"
                                title={callToAction?.title}
                                tabIndex={-1}
                            />
                        )}
                    </>
                )}
        </>
    );
};
