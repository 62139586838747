import React from 'react';
import { Link, VideoMedia } from '~/lib/data-contract';
import { useRouter } from 'next/router';
import { useCookieConsent } from '$shared/hooks';
import { Video } from '$shared/components';

type M10VideoPropsType = {
    isFullWidth: boolean;
    video?: VideoMedia;
    callToAction?: Link;
    imageSrc?: string;
    posterSizes?: string;
};
export const M10HeroVideo = ({
    video,
    isFullWidth,
    callToAction,
    imageSrc,
    posterSizes,
}: M10VideoPropsType) => {
    const router = useRouter();
    const shouldLinkCover = Boolean(isFullWidth && callToAction?.url);
    const { marketing, statistic } = useCookieConsent();

    const handleHeroClick = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        if (callToAction?.url) {
            router.push(callToAction.url);
        }
    };

    return (
        <>
            {video?.src &&
                ((marketing && statistic) || (!(marketing && statistic) && !imageSrc)) && (
                    <Video
                        controls={false}
                        src={video.src}
                        loop={video.loop}
                        playing={isFullWidth || video.autoPlay}
                        posterSrc={imageSrc}
                        cover={true}
                        buttonPosition={isFullWidth ? 'corner' : 'center'}
                        onOverlayClick={shouldLinkCover ? handleHeroClick : undefined}
                        showCustomButtons={video.controls}
                        posterSizes={posterSizes}
                    />
                )}
        </>
    );
};
