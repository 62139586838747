// Change the cropped image ratio, based on the height and the mediaLayout of the image
export const imageRatio = {
    mobile: {
        full: {
            compact: [5, 2],
            narrow: [1, 1],
            normal: [3, 4],
            tall: [2, 3],
        },
        split: {
            compact: [5, 2],
            narrow: [5, 4],
            normal: [5, 4],
            tall: [5, 4],
        },
    },
    desktop: {
        full: {
            compact: [6, 1],
            narrow: [7, 2],
            normal: [17, 6],
            tall: [32, 15],
        },
        split: {
            compact: [4, 1],
            narrow: [7, 3],
            normal: [17, 9],
            tall: [22, 15],
        },
    },
};

// Do this so we can do nothing for the first 10% of the parallax.
// Helps a little bit in chrome to avoid a visual glitch where the background can become visible when scrolling down, then up very fast.
export const parallaxInputRange = [0, 0.1, 1];
export const parallaxOutputRangeMedia = ['0', '0', '20%'];
export const parallaxOutputRangeTextBlock = ['0', '0', '15%'];
