import { CSSObject } from '@emotion/react';
import { transparentize, readableColorIsBlack } from 'color2k';

type Position = 'left' | 'right' | 'bottom' | 'center';

/**
 * Creates a light or dark background that is left, right, bottom or center
 */
export const gradient = (background = '#FFF', position: Position = 'center'): CSSObject => {
    const linearGradientOpacity = readableColorIsBlack(background) ? [0.5, 0.6] : [0.6, 0.7];
    const radialGradientOpacity = [0.6, 0.9];
    const linearGradientColor = [
        transparentize(background, linearGradientOpacity[0]),
        transparentize(background, linearGradientOpacity[1]),
    ];
    const radialGradientColor = [
        transparentize(background, radialGradientOpacity[0]),
        transparentize(background, radialGradientOpacity[1]),
    ];

    switch (position) {
        case 'left':
            return {
                background: `linear-gradient(
                    to right,
                    ${linearGradientColor[0]} 0%,
                    ${linearGradientColor[1]} 40%,
                    transparent 60%
                )`,
            };
        case 'right':
            return {
                background: `linear-gradient(
                    to left,
                    ${linearGradientColor[0]} 0%,
                    ${linearGradientColor[1]} 40%,
                    transparent 60%
                )`,
            };
        case 'bottom':
            return {
                background: `linear-gradient(
                    to top,
                    ${linearGradientColor[0]} 0%,
                    ${linearGradientColor[1]} 75%,
                    transparent
                )`,
            };
        case 'center':
            return {
                background: `radial-gradient(
                    40% 75%,
                    ${radialGradientColor[0]} 0%,
                    ${radialGradientColor[1]} 80%,
                    transparent
                )`,
            };
        default:
            return {};
    }
};
